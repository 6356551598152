var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"09809cd2e0ec89ee7da54248d8e43e6880b655f5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.05,
  profilesSampleRate: 0.05,
  beforeSend(event) {
    if (event.exception) {
      const frames = event.exception.values?.[0]?.stacktrace?.frames;
      if (frames) {
        const errorFileName = frames[frames.length - 1]?.filename;
        if (errorFileName && errorFileName.includes("app-page.runtime")) {
          return null;
        }
      }
    }
    return event;
  },
  integrations: [new Integrations.BrowserTracing()],
});
